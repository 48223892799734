<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/procedures"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.procedureDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="odn-procedure-name">
        {{ procedure.name }}
      </div>
      <ion-card v-if="procedure.project">
        <ion-card-header>
          <ion-card-subtitle>{{
            procedure.project.customer.name
          }}</ion-card-subtitle>
          <ion-card-title>{{ procedure.project.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ODNBadges>
            <ODNBadge :color="procedure.project.projectStatus.color">
              {{
                $t(`projectStatuses.${procedure.project.projectStatus.name}`)
              }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{
                  color: procedure.project.hasSurveys ? '#2dd36f' : '#eb445a',
                }"
              />
              {{ $t('labels.hasSurveys') }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon
                :icon="icons.ellipse"
                :style="{
                  color: procedure.project.hasTickets ? '#2dd36f' : '#eb445a',
                }"
              />
              {{ $t('labels.hasTickets') }}
            </ODNBadge>
          </ODNBadges>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>
      <!-- Content -->
      <div v-html="procedure.content" class="odn-procedure-content"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonRippleEffect,
  IonIcon,
} from '@ionic/vue';
import { ellipse, documentsOutline } from 'ionicons/icons';

import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

export default {
  name: 'OfflineProcedureDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonRippleEffect,
    IonIcon,
    ODNBadges,
    ODNBadge,
  },
  data() {
    return {
      icons: {
        ellipse,
        documentsOutline,
      },
    };
  },
  computed: {
    ...mapState('procedures', {
      procedure: 'selectedProcedure',
    }),
  },
};
</script>

<style lang="scss">
.odn-procedure {
  &-name {
    padding: 16px 16px 8px 16px;
    font-size: 20px;
  }
  &-content {
    padding: 0 16px;
  }
}
</style>
